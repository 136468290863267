import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

export default function LoginForm() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
  });

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const response = await login(data.email, data.password);
      if (response?.status === 200) {
        navigate('/auth/verify', {
          state: { email: data.email, password: data.password, redirect: pathname },
        });
      }
      if (response?.status === 401) {
        setError('afterSubmit', { message: 'Incorrect username or password' });
      }
      if (response?.status === 500) {
        setError('afterSubmit', { message: 'Something went wrong, please try again' });
      }
    } catch (error) {
      setError('afterSubmit', { ...error, message: error.message });
      if (error?.message === 'User is deactivated') {
        navigate(PATH_AUTH.loginDisabled);
      }

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors?.afterSubmit && <Alert severity="error">{errors?.afterSubmit?.message}</Alert>}

        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Login
      </LoadingButton>

      <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
        <Link
          component={RouterLink}
          variant="subtitle1"
          to={'/privacy'}
          style={{ color: '#707070', textDecoration: 'underline' }}
        >
          {/* <a
          href="https://google.com"
          target="_blank"
          rel="noreferrer"
          variant="subtitle1"
          style={{
            color: '#707070',
            textDecoration: 'underline',
            font: 'Open Sans',
            marginTop: '8px',
            fontSize: '14px',
            fontWeight: '700',
            lineHeight: '22px',
          }}
        > */}
          Terms & Conditions {/* </a> */}
        </Link>
      </Stack>
    </FormProvider>
  );
}

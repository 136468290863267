import {
  Autocomplete,
  Box,
  FormControl,
  MenuItem,
  Popper,
  Select,
  SelectChangeEvent,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { IEntity } from 'src/@types/Schemas/entity_schema';
import { selectEntity } from 'src/redux/slices/user';
import { dispatch, useSelector } from 'src/redux/store';
import { pxToRem } from 'src/utils/getFontValue';

const AUTOCOMPLETE_THRESHOLD = 10;

export default function EntitySelect() {
  const { selected_entity, entities } = useSelector((state: any) => state.user);
  const theme = useTheme();

  const handleSelectChange = (event: SelectChangeEvent) => {
    const selectedId = event.target.value;
    const entity = entities.find((e: IEntity) => e.id === selectedId);
    if (entity) {
      dispatch(selectEntity(entity));
    }
  };

  const handleAutocompleteChange = (_: React.SyntheticEvent, value: IEntity | null) => {
    if (value) {
      dispatch(selectEntity(value));
    }
  };

  const StyledPopper = styled(Popper)(() => ({
    '& .MuiAutocomplete-listbox': {
      padding: 0,
      margin: 0,
      backgroundColor: 'white',
      borderRadius: 0,
    },
    '& .MuiAutocomplete-listbox .MuiAutocomplete-option': {
      padding: '10px',
      margin: 0,
      borderRadius: 0,
    },
  }));

  return (
    <Box
      sx={{
        minWidth: 200,
        [theme.breakpoints.up('md')]: {
          width: 250,
        },
      }}
    >
      <FormControl fullWidth>
        {entities.length > AUTOCOMPLETE_THRESHOLD ? (
          <Autocomplete
            aria-label="Select an organisation"
            options={entities}
            getOptionLabel={(option: IEntity) => option.name || ''}
            value={selected_entity || null}
            onChange={handleAutocompleteChange}
            isOptionEqualToValue={(option: IEntity, value: IEntity | null) =>
              option.id === value?.id
            }
            noOptionsText={<Typography variant="body2">No company found</Typography>}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%',
                  '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                  '& .MuiInputBase-input': {
                    fontWeight: 600,
                    lineHeight: 22 / 14,
                    fontSize: pxToRem(14),
                  },
                }}
              />
            )}
            renderOption={(props, option) => (
              <Box
                component="li"
                {...props}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  borderRadius: 0,
                }}
              >
                <Typography variant="subtitle2" noWrap>
                  {option.name}
                </Typography>
              </Box>
            )}
            disableClearable
            PopperComponent={(props) => <StyledPopper {...props} />}
          />
        ) : (
          <Select
            aria-label="Select an organisation"
            value={selected_entity ? String(selected_entity.id) : ''}
            onChange={handleSelectChange}
            displayEmpty
            MenuProps={{
              PaperProps: {
                sx: {
                  '& .MuiList-root': { padding: 0 },
                  '& .MuiMenuItem-root': { padding: '10px' },
                },
              },
            }}
            sx={{ '& .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
          >
            {entities.length > 0 ? (
              entities.map((entity: IEntity) => (
                <MenuItem key={entity.id} value={entity.id} sx={{ width: 250 }}>
                  <Typography variant="subtitle2" noWrap>
                    {entity.name}
                  </Typography>
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>
                <Typography variant="body2" noWrap>
                  No Entities
                </Typography>
              </MenuItem>
            )}
          </Select>
        )}
      </FormControl>
    </Box>
  );
}
